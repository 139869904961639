import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import BreadCrumb from "../components/BreadCrumb";
import ProductGrid from "../components/ProductGrid";
import SEO from "../components/Seo";
const IndexPage = (props) => {
  const { data } = props;
  return (
    <Layout details={data}>
        <SEO
        pathname="/"
        title={`Nastesco Marine Pte Ltd | Products`}
        keywords={["Nastesco Home","Nastesco Landing Page","Nastesco Products"]}
        />
      <BreadCrumb
        list={[
          {
            title: "Products",
            href: "/products",
            active: true,
          },
        ]}
      />
      {/* <div className="px-[15px] py-[20px]">
        <span className="font-bold text-lg">Our Products</span>
      </div> */}
     <ProductGrid products ={data?.products?.nodes}  product_categories ={data?.product_categories?.nodes} />
    </Layout>
  );
};

export const query = graphql`
  query {
    settings: allSanitySettings {
      nodes {
        id
        address_details {
          address_text
          city
          country
          office_location {
            latitude
            longitude
          }
          site_email
          site_phone
          state
        }
        business_hours {
          closed
          open
        }
        copyright_year
        shipserve_details {
          logo {
            asset {
              url
            }
          }
          trade_id
        }
        site_logo {
          asset {
            url
          }
        }
        site_name
        social_links {
          facebook_link
          linkedin_link
          tweeter_link
        }
      }
    }
    company_profile: allSanityCompanyProfile {
      nodes {
        about_us
        full_description {
          description
          description_image {
            asset {
              url
            }
          }
        }
        policy_details {
          description
          policy_image {
            asset {
              url
            }
          }
        }
      }
    }
    gallery: allSanityGallery {
      nodes {
        _id
        description
        title
        image {
          asset {
            url
          }
        }
      }
      totalCount
    }
    product_categories: allSanityProductCategory {
      nodes {
        title
        description
        _id
      }
      totalCount
    }
    products: allSanityProducts {
      totalCount
      nodes {
        _id
        detailed_description
        image {
          asset {
            url
          }
        }
        product_category {
          title
          _id
        }
        sort_description
        title
        sub_part_details {
          description
          title
          image {
            asset {
              url
            }
          }
        }
      }
    }
    services: allSanityServices {
      totalCount
      nodes {
        _id
        value_added_services
        key_services
      }
    }
    slider: allSanitySlider {
      totalCount
      nodes {
        _id
        slider_item {
          description
          title
          image {
            asset {
              url
            }
          }
        }
      }
    }
  }
`;
export default IndexPage;
